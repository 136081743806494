import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ThemeService } from './_services/theme.service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var epaas: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  enableChat = environment.EnableChat;
  constructor(private titleService: Title, public themeService: ThemeService, private router: Router,
    @Inject(DOCUMENT) private _document: Document) {
    let make = this.themeService.getMake();

    if (make === 'BMW') {
      this.titleService.setTitle("BMW EKYC");
      this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/bmw.ico');
      this.loadLiveChat();
    }
    else if (make === 'Motorrad') {
      this.titleService.setTitle("BMW Motorrad EKYC");
      this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/bmw.ico');
    }
    else {
      this.titleService.setTitle("MINI EKYC");
      this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/mini.ico');
    }

    this.loadEPaas();
    this.InitilizeEPaaS(make);
    this.loadCSS(make);
    //if (environment.production)
      this.loadScript();
  }

  ngOnInit() {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects;
      this.enableChat = !url.includes('/ocrredirect');
    });
}

  loadScript() {
    let js = document.createElement('script');
    js.src = '/assets/js/LogRocket.min.js';
    js.type = 'text/javascript';
    js.onload = () => {
      const LogRocket = (window as any).LogRocket;
      LogRocket.init(environment.LocRocketId);
    };
    js.async = true;
    js.crossOrigin = "anonymous";
    document.getElementsByTagName('head')[0].appendChild(js);
  }
  
  public loadLiveChat() {
    if (environment.EnableChat) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = false;
      script.id = 'cctchat';
      script.src = environment.CCTChat;
      document.getElementsByTagName('body')[0].appendChild(script);
    }
  }

  public initChat() {
    window["openCCTChatWidget"]();
  }

  private loadCSS(brand: string) {
    const epaasFont = document.createElement('link');
    epaasFont.id = 'client-theme';
    epaasFont.rel = 'stylesheet';
    epaasFont.type = 'text/css';
    if (brand === 'BMW') {
      epaasFont.href = environment.StaticFilePath + '/fonts/epaas_bmwfonts_base64.css';
    }
    else if (brand === 'MINI') {
      epaasFont.href = environment.StaticFilePath + '/fonts/epaas_minifonts_base64.css';
    }
    else {
      epaasFont.href = environment.StaticFilePath + '/fonts/epaas_motorradfonts_base64.css';
    }

    document.getElementsByTagName('head')[0].appendChild(epaasFont);
  }

  loadEPaas() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.id = 'epaas';
    script.src = 'https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js';    
    document.getElementsByTagName('body')[0].appendChild(script);
  }

  private InitilizeEPaaS(brand: string) {

    var script = document.querySelector('#epaas');
    script.addEventListener('load', function() {
      if (epaas != null) {
        var TenantId = "engage_bmw_com_my";
        if (brand === 'MINI') {
          TenantId = "engage_mini_my";
        }
        else if (brand === 'Motorrad') {
          TenantId = "engage_bmw-motorrad_com_my";
        }
        epaas.api.initialize({ tenant: TenantId, locale: 'en_MY' });
      }
    });
  }
}